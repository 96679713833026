var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-4 mb-4"},[_c('div',{staticClass:"col-span-3 p-4 l-box-shadow bg-white-pure rounded"},[_c('div',{staticClass:"grid grid-cols-3 gap-4 gap-y-8"},[_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"baby"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 average-age"},[_vm._v("Average Age "),_c('v-tooltip',{attrs:{"top":"","attach":".average-age"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.data.avgAgeOfOpenClients != null ? _vm.data.avgAgeOfOpenClients : 'N/A'))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"users"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 total-clients"},[_vm._v("Total Clients "),_c('v-tooltip',{attrs:{"top":"","attach":".total-clients"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getSubPropValue(_vm.data.numOpenEpisodes, 'total')))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"sign-language"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 atsi"},[_vm._v("ATSI "),_c('v-tooltip',{attrs:{"top":"","attach":".atsi"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getAsPercentage(_vm.data.numAtsi)))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"gavel"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 jy-justice"},[_vm._v("Juvenile/Youth Justice "),_c('v-tooltip',{attrs:{"top":"","attach":".jy-justice"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getSubPropValue(_vm.data.numPerReferralSource, 'JJ / YJ') != '-' ? _vm.getSubPropValue(_vm.data.numPerReferralSource, 'JJ / YJ') : 0))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"briefcase-medical"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 mental-health-issues"},[_vm._v("Mental Health Issues "),_c('v-tooltip',{attrs:{"top":"","attach":".mental-health-issues"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getAsPercentage(_vm.data.numClientsMentalHealth)))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"heartbeat"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 attempted-suicide"},[_vm._v("Attempted Suicide "),_c('v-tooltip',{attrs:{"top":"","attach":".attempted-suicide"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Tooltip.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getAsPercentage(_vm.data.numClientsAttemptedSuicide)))])])],1)])]),_c('div',{staticClass:"col-span-4 l-box-shadow bg-white-pure rounded mb-4 p-4"},[_c('h2',{staticClass:"text-black text-base lg:text-lg mb-4"},[_vm._v("Referral Sources")]),_c('div',{staticClass:"flex items-center justify-center w-4/5"},[(!_vm.loading)?_c('BarChart',{staticClass:"block",attrs:{"height":540,"title":_vm.referralSourcesData.title,"chartData":_vm.referralSourcesData.chartData,"label":_vm.referralSourcesData.label,"scope":_vm.referralSourcesData.scope,"showDataLabels":true}}):_vm._e()],1)]),_c('div',{staticClass:"col-span-3 l-box-shadow bg-white-pure rounded mb-4 p-4"},[_c('h2',{staticClass:"text-black text-base lg:text-lg mb-4"},[_vm._v("Gender")]),(!_vm.loading)?_c('BarChart',{staticClass:"block",attrs:{"height":540,"title":_vm.genderData.title,"chartData":_vm.genderData.chartData,"label":_vm.genderData.label,"scope":_vm.genderData.scope,"showDataLabels":true}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }