<template>
    <div>
        <FormsChartAndDatatable :data-obj="dataObj" :headers="headers" :loading="loading"/>
        <StreetUniVisitCharts :data-obj="dataObj" :loading="loading"/>
        <AnnualKpiDataTable :data-obj="dataObj" :loading="loading"/>
        <ReferralSourcesAndGenderCharts :data-obj="dataObj" :loading="loading"/>
        <!-- end -->
    </div>
</template>

<script>
import AnnualKpiDataTable from '@/components/clinical/partials/AnnualKpiDataTable'
import StreetUniVisitCharts from '@/components/clinical/partials/StreetUniVisitCharts'
import ReferralSourcesAndGenderCharts from '@/components/clinical/partials/ReferralSourcesAndGenderCharts'
import FormsChartAndDatatable from '@/components/clinical/partials/FormsChartAndDatatable'

export default {
    name: 'StreetUniClinical',
    components: { AnnualKpiDataTable, StreetUniVisitCharts, ReferralSourcesAndGenderCharts, FormsChartAndDatatable },
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
