<script>
import { Bar, mixins } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
        scope: {
            type: Object,
            default: null
        },
        showDataLabels: {
            type: Boolean,
            default: false
        },
        label: null
    },
    mounted() {
        this.addPlugin(ChartDataLabels)
        this.renderChart(
            this.chartData,
            {
                title: {
                    display: true,
                    text: [this.title, ''],
                    fontSize: 15,
                    fontColor: '#1C1717',
                    fontFamily: "Open Sans",
                },
                legend: {
                    display: false
                },
                plugins: {
                    tooltip: {
                        titleFont: {
                            size: 16
                        },
                        bodyFont: {
                            size: 16
                        },
                    },
                    datalabels: {
                        display: this.showDataLabels,
                        color: '#9AA4B3',
                        anchor: 'end',
                        align: 'end',
                        textAlign: 'center',
                        font: {
                            fontFamily: "Open Sans",
                            size: 11
                        },
                        formatter: (value) => {
                            return value ? value : ''
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    yAlign: 'bottom',
                    xAlign: 'center',
                    titleAlign: 'center',
                    bodyAlign: 'center',
                    custom: (tooltip) => {
                        if (tooltip)
                            tooltip.displayColors = false
                    },
                    callbacks: {
                        title: () => '',
                        label: this.label
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            fontSize: 11,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            fontColor: '#9AA4B3',
                            fontFamily: "Open Sans",
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            display: true,
                            color: '#EEF0F1'
                        },
                        ticks: {
                            fontSize: 11,
                            fontColor: '#9AA4B3',
                            fontFamily: "Open Sans",
                            ...this.scope,
                            beginAtZero: false,
                            callback: (value, index) => { if (value != 0) return value}
                        }
                    }]
                }
            }
        )
    },
    watch: {
        chartData() {
            this.$data._chart.update()
        }
    }
}
</script>
