<template>
    <div>
        <div class="grid grid-cols-10 gap-4 mb-4">
            <div class="col-span-6 l-box-shadow bg-white-pure p-4 rounded c-staff__table editable_table flex flex-col w-full">
                <v-data-table
                    :headers="headers"
                    :items="dataTable"
                    :items-per-page="6" 
                    :item-class="setRowClass"
                    class="a-table w-full"
                    :hide-default-footer="false"
                ></v-data-table>
            </div>
            <div class="col-span-4 rounded p-4 l-box-shadow bg-white-pure">
                <div class="flex items-center justify-between mb-1">
                    <h1 class="text-black text-base lg:text-lg">Forms</h1>
                </div>

                <div class="flex items-center justify-center w-3/5">
                    <BarChart
                        v-if="!loading"
                        :height="300"
                        :title="formsData.title"
                        :chartData="formsData.chartData"
                        :label="formsData.label"
                        :scope="formsData.scope"
                        :showDataLabels="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import {DASHA_FORMS_COLOURS} from '@/utils/constants'
import {useDashaHelpers} from '@/composables/dashaHelpers'

export default {
    name: 'FormsChartAndDatatable',
    components: { BarChart },
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const dataTable = ref([])
        const { getRoundedValue } = useDashaHelpers()
        
        const formsData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: DASHA_FORMS_COLOURS,
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })
        const setRowClass = (item) => {
            return item.kpi === "TSU & Counselling total" ? "opacity-25" : ""
        }
        const dataKeys = ref([
            {
                rowTitle: 'Total Logins',
                property: 'campusCheckinsTotal'
            },
            {
                rowTitle: 'New Service users',
                property: 'newOpenedEpisodes'
            },
            {
                rowTitle: 'Service users total',
                property: 'curOpenEpisodes'
            },
            {
                rowTitle: 'Brief assessments total',
                property: 'completedBriefAssessments'
            },
            {
                rowTitle: 'TSU & Counselling total',
                property: 'tsuWithCounsellingEpisodes'
            },
            {
                rowTitle: 'Total no, of workshops',
                property: 'numWorkshops'
            },
            {
                rowTitle: 'Total workshop attendees',
                property: 'numWorkshopAttendees'
            }
        ])

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(data, (newData) => {
            //getting the forms data
            dataTable.value = []
            const fd = newData.completedForms
            formsData.value.chartData.labels = fd.map((e) => e.title)
            formsData.value.chartData.datasets[0].data = fd.map((e) => e.value)
            for (const key of dataKeys.value) {
                const valueData = newData[key.property]
                // console.log(valueData.total)
                if (valueData) {
                    dataTable.value.push({
                        kpi: key.rowTitle,
                        total: getRoundedValue(valueData.total),
                        monthlyKpi: getRoundedValue(valueData.monthly_kpi),
                        ytdActual: getRoundedValue(valueData.ytd),
                        annualKpi: getRoundedValue(valueData.annual_kpi)
                    })
                }
            }
        }, { deep: true })
        //#endregion
        return {
            dataTable,
            formsData,
            getRoundedValue,
            setRowClass
        }
    }
}
</script>
