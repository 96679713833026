<template>
    <div>
        <div class="grid grid-cols-5 gap-4 p-4 l-box-shadow bg-white-pure mb-4">
            <div class="flex flex-row">
                <fa-icon icon="folder-open" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1 programs-open">Programs Open (Current)
                        <v-tooltip top attach=".programs-open">
                            <template v-slot:activator="{ on, attrs3 }">
                                <span
                                    v-bind="attrs3"
                                    v-on="on"
                                >
                                <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                            </template>
                            <span>This is current real-time data (updated hourly) and is not affected by the date range filters.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{getSubPropValue(dataObj.curOpenEpisodes,'total')}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="sign-in-alt" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1 relative referred">Referred (Current)
                        <v-tooltip top attach=".referred">
                            <template v-slot:activator="{ on, attrs3 }">
                                <span
                                    v-bind="attrs3"
                                    v-on="on"
                                >
                                <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                            </template>
                            <span>This is current real-time data (updated hourly) and is not affected by the date range filters.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.newReferredEpisodes}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="user-check" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1 assessed-suitable">Assessed Suitable (Current)
                        <v-tooltip top attach=".assessed-suitable">
                            <template v-slot:activator="{ on, attrs3 }">
                                <span
                                    v-bind="attrs3"
                                    v-on="on"
                                >
                                <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                            </template>
                            <span>This is current real-time data (updated hourly) and is not affected by the date range filters.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.curAssessedSuitableEpisodes}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="clipboard-check" class="mr-2 text-primary-light text-lg" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1 full-assessments-completed">Full Assessments Completed
                        <v-tooltip top attach=".full-assessments-completed">
                            <template v-slot:activator="{ on, attrs3 }">
                                <span
                                    v-bind="attrs3"
                                    v-on="on"
                                >
                                <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                            </template>
                            <span>This is for the selected period.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">
                        {{ getSubPropValue(dataObj.completedComprehensiveAssessments, 'total')}}
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="tasks" class="mr-2 text-primary-light text-lg" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1 brief-assessments-completed">Brief Assessments Completed
                        <v-tooltip top attach=".brief-assessments-completed">
                            <template v-slot:activator="{ on, attrs3 }">
                                <span
                                    v-bind="attrs3"
                                    v-on="on"
                                >
                                <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                            </template>
                            <span>This is for the selected period.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">
                        {{getSubPropValue(dataObj.completedBriefOrDdAssessments, 'total')}}
                    </div>
                </div>
            </div>
        </div>
        <NonResKpiAndCharts :data-obj="dataObj" :headers="headers" :loading="loading"/>
        <ReferralStatistics :data-obj="dataObj" :loading="loading"/>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import PieChart from '@/components/charts/PieChart'
import BarChart from '@/components/charts/BarChart'
import NonResKpiAndCharts from '@/components/clinical/partials/NonResKpiAndCharts.vue'
import ReferralStatistics from '@/components/clinical/partials/ReferralStatistics.vue'
import { useDashaHelpers } from '@/composables/dashaHelpers'


export default {
    name: 'NonResidentialClinical',
    components: { PieChart, BarChart, NonResKpiAndCharts, ReferralStatistics },
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const periodOptions = ref(['Last 7 days', 'Month to date', 'Financial year', 'Custom range'])
        const yearOptions = ref(['2021', '2020', '2019'])
        const {
            getSubPropValue
        } = useDashaHelpers()

        return {
            periodOptions,
            yearOptions,
            getSubPropValue,
        }
    }
}
</script>
