<template>
    <div>
        <div class="grid grid-cols-10 gap-4 mb-4">
            <div class="col-span-3 p-4 l-box-shadow bg-white-pure rounded">
                <div class="grid grid-cols-3 gap-4 gap-y-8">
                    <div class="flex flex-row">
                        <fa-icon icon="baby" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 average-age">Average Age
                                <v-tooltip top attach=".average-age">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{data.avgAgeOfOpenClients != null ? data.avgAgeOfOpenClients : 'N/A'}}</div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="users" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 total-clients">Total Clients
                                <v-tooltip top attach=".total-clients">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{getSubPropValue(data.numOpenEpisodes, 'total')}}</div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="sign-language" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 atsi">ATSI
                                <v-tooltip top attach=".atsi">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{ getAsPercentage(data.numAtsi) }}</div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="gavel" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 jy-justice">Juvenile/Youth Justice
                                <v-tooltip top attach=".jy-justice">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{ getSubPropValue(data.numPerReferralSource, 'JJ / YJ') != '-' ? getSubPropValue(data.numPerReferralSource, 'JJ / YJ') : 0 }}</div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="briefcase-medical" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 mental-health-issues">Mental Health Issues
                                <v-tooltip top attach=".mental-health-issues">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{ getAsPercentage(data.numClientsMentalHealth) }}</div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="heartbeat" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 attempted-suicide">Attempted Suicide
                                <v-tooltip top attach=".attempted-suicide">
                                    <template v-slot:activator="{ on, attrs3 }">
                                        <span
                                            v-bind="attrs3"
                                            v-on="on"
                                        >
                                        <fa-icon icon="question-circle" class="mr-2 text-xs text-grey-3" /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">{{ getAsPercentage(data.numClientsAttemptedSuicide) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-4 l-box-shadow bg-white-pure rounded mb-4 p-4">
                <h2 class="text-black text-base lg:text-lg mb-4">Referral Sources</h2>

                <div class="flex items-center justify-center w-4/5">
                    <BarChart
                        v-if="!loading"
                        class="block"
                        :height="540"
                        :title="referralSourcesData.title"
                        :chartData="referralSourcesData.chartData"
                        :label="referralSourcesData.label"
                        :scope="referralSourcesData.scope"
                        :showDataLabels="true"
                    />
                </div>
            </div>
            <div class="col-span-3 l-box-shadow bg-white-pure rounded mb-4 p-4">
                <h2 class="text-black text-base lg:text-lg mb-4">Gender</h2>

                <BarChart
                    v-if="!loading"
                    class="block"
                    :height="540"
                    :title="genderData.title"
                    :chartData="genderData.chartData"
                    :label="genderData.label"
                    :scope="genderData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import NonResKpiAndCharts from '@/components/clinical/partials/NonResKpiAndCharts'
import {
    DASHA_GENDER_COLOURS,
    DASHA_REFERRAL_COLOURS
} from '@/utils/constants'
import { useDashaHelpers } from '@/composables/dashaHelpers'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'ReferralStatistics',
    components: { BarChart, NonResKpiAndCharts },
    props: {
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { genderOptions } = useGetters(['genderOptions'])
        const {
            getAsPercentage,
            getSubPropValue
        } = useDashaHelpers()

        const genderData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: Object.values(DASHA_GENDER_COLOURS),
                    data: [],
                    barThickness: 50
                }]
            }
        })
        const referralSourcesData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: DASHA_REFERRAL_COLOURS,
                    data: [],
                    barThickness: 50
                }]
            }
        })
        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(data, () => {


            //TODO: move to a function
            const numClientsByGender = data.value?.numClientsByGender
            if (numClientsByGender) {
                console.log('genderOptions', genderOptions.value)
                genderData.value.chartData.labels = Object.keys(numClientsByGender)
                    .map(k => genderOptions.value.find(g => g.value === k)?.label || '')
                genderData.value.chartData.datasets[0].data = Object.values(numClientsByGender)
            }

            //TODO: move to a function
            const numPerReferralSource = data.value?.numPerReferralSource
            if (numPerReferralSource) {
                referralSourcesData.value.chartData.labels = Object.keys(numPerReferralSource)
                referralSourcesData.value.chartData.datasets[0].data = Object.values(numPerReferralSource)
            }
        }, {deep: true})
        //#endregion

        return {
            data,
            genderData,
            referralSourcesData,
            getAsPercentage,
            getSubPropValue,
        }
    }
}
</script>