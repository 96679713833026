<template>
    <div>
        <div class="grid grid-cols-10 gap-4 mb-4">
            <div class="col-span-5 l-box-shadow bg-white-pure rounded mb-4 p-4">
                <div class="flex gap-x-1">
                    <h2 class="text-black text-base lg:text-lg mb-4">Referral Sources</h2>
                    <v-tooltip top attach=".atsi">
                        <template v-slot:activator="{on, attrs3}">
                            <span v-bind="attrs3" v-on="on">
                                <fa-icon
                                    icon="question-circle"
                                    class="mr-2 text-xs text-grey-3"
                            /></span>
                        </template>
                        <span>Referral sources for clients with a brief assessment during the period selected.</span>
                    </v-tooltip>
                </div>
                <div class="flex items-center justify-center w-4/5">
                    <BarChart
                        v-if="!loading"
                        class="block"
                        :height="540"
                        :title="referralSourcesData.title"
                        :chartData="referralSourcesData.chartData"
                        :label="referralSourcesData.label"
                        :scope="referralSourcesData.scope"
                        :showDataLabels="true"
                    />
                </div>
            </div>
            <div class="col-span-5 l-box-shadow bg-white-pure rounded mb-4 p-4">
                <h2 class="text-black text-base lg:text-lg mb-4">Gender</h2>

                <BarChart
                    v-if="!loading"
                    class="block"
                    :height="540"
                    :title="genderData.title"
                    :chartData="genderData.chartData"
                    :label="genderData.label"
                    :scope="genderData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import {DASHA_GENDER_COLOURS, DASHA_REFERRAL_COLOURS} from '@/utils/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const {useGetters} = createNamespacedHelpers('')

export default {
    name: 'ReferralSourcesAndGenderCharts',
    components: {BarChart},
    props: {
        dataObj: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const {genderOptions} = useGetters(['genderOptions'])

        const genderData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: Object.values(DASHA_GENDER_COLOURS),
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })
        const referralSourcesData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: DASHA_REFERRAL_COLOURS,
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(
            data,
            () => {
                //TODO: move to a function
                const numClientsByGender = data.value?.numClientsByGender
                if (numClientsByGender) {
                    genderData.value.chartData.labels = Object.keys(numClientsByGender).map(
                        (k) => genderOptions.value.find((g) => g.value === k)?.label || ''
                    )
                    genderData.value.chartData.datasets[0].data = Object.values(numClientsByGender)
                }

                //TODO: move to a function
                const numPerReferralSource = data.value?.numPerReferralSource
                if (numPerReferralSource) {
                    referralSourcesData.value.chartData.labels = Object.keys(numPerReferralSource)
                    referralSourcesData.value.chartData.datasets[0].data =
                        Object.values(numPerReferralSource)
                }
            },
            {deep: true}
        )
        //#endregion
        return {
            genderData,
            referralSourcesData
        }
    }
}
</script>
