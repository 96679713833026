<template>
    <div>
        <div class="grid grid-cols-10 gap-4 mb-4">
            <div class="col-span-6 l-box-shadow bg-white-pure p-4 rounded c-staff__table editable_table expandable_table flex flex-col w-full">
                <v-data-table
                    id="NonResKpiTable"
                    :headers="headers"
                    :items="dataTable"
                    :items-per-page="15"
                    item-key="kpi"
                    :item-class="setRowClass"
                    class="a-table w-full"
                    show-expand
                    single-expand
                    :expanded.sync="expanded"
                    :hide-default-footer="true"
                >
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="flex-row m-0 w-full sub-row-td">

                            <table
                                class="table-auto w-full"
                                v-for="(r, idx) in item.subRows"
                                :key="`${r.kpi}-${idx}`"
                            >
                                <tr v-if="item.kpi == 'Treatment outcome Follow Ups'">
                                    <td class="text-left w-full">- {{r.kpi}}</td>
                                    <td class="left w-full"></td>
                                    <td class="left w-full"></td>
                                    <td class="text-left ml-2 w-full">
                                        <span class="ml-5">{{r.total}} </span>
                                    </td>
                                    <td class="left w-full"></td>
                                </tr>
                                <tr v-else>
                                    <td>- {{r.kpi}}</td>
                                    <td>{{r.total}}</td>
                                    <td class="left">{{r.annualKpi}}</td>
                                    <td class="left">{{r.monthlyKpi}}</td>
                                    <td class="left">{{r.ytd}}</td>
                                </tr>
                            </table>
                        </td>
                            <!-- TODO: remove the datatable if we aren't using it-->
<!--                            <v-data-table-->
<!--                                hide-default-header-->
<!--                                hide-default-footer-->
<!--                                :headers="headers"-->
<!--                                :items="item.subRows"-->
<!--                                :items-per-page="6"-->
<!--                                class="a-table w-full border-none"-->
<!--                            >-->
<!--                                <template v-slot:item.kpi="{ item }">-->
<!--                                    - {{ item.kpi }}-->
<!--                                </template>-->
<!--                            </v-data-table>-->
                    </template>
                    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                        <v-icon
                            v-if="item.subRows"
                            :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                            @click.stop="expand(!isExpanded)"
                        >$expand</v-icon>
                    </template>
                </v-data-table>
            </div>
            <div class="col-span-4">
                <div class="rounded p-4 l-box-shadow bg-white-pure mb-4">
                    <div class="flex items-center justify-between mb-1">
                        <h1 class="text-black text-base lg:text-lg">Drugs Used</h1>
                    </div>
                    <div class="flex items-center justify-center w-3/5">
                        <BarChart
                            v-if="!loading"
                            :height="300"
                            :title="drugsUsedData.title"
                            :chartData="drugsUsedData.chartData"
                            :label="drugsUsedData.label"
                            :scope="drugsUsedData.scope"
                            :showDataLabels="true"
                        />
                    </div>
                </div>
                <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex items-center justify-between mb-1">
                        <h1 class="text-black text-base lg:text-lg">Forms</h1>
                    </div>
                    <div class="flex items-center justify-center w-3/5">
                        <BarChart
                            v-if="!loading"
                            :height="300"
                            :title="formsData.title"
                            :chartData="formsData.chartData"
                            :label="formsData.label"
                            :scope="formsData.scope"
                            :showDataLabels="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import NonResKpiAndCharts from '@/components/clinical/partials/NonResKpiAndCharts'
import {
    DASHA_FORMS_COLOURS,
    DASHA_VISITS_BY_SITES_COLOURS
} from '@/utils/constants'
import { useDashaHelpers } from '@/composables/dashaHelpers'

export default {
    name: 'NonResKpiAndCharts',
    components: { BarChart, NonResKpiAndCharts },
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const dataTable = ref([])
        const expanded = ref([])
        const {
            getRoundedValue,
            isNullOrUndefined
        } = useDashaHelpers()

        const formsData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: ['Full assessment', 'TOD', 'CSQ-8'],
                datasets: [{
                    backgroundColor: DASHA_FORMS_COLOURS,
                    data: [],
                    barThickness: 50
                }]
            }
        })
        const setRowClass = (item) => {
            let newClass = ""
            const arr = ["Total no. of CSQ-8", "Total no. of eligible CSQ-8", "Average wait time"]
            arr.map(e => {
                if (item.kpi === e) newClass = "opacity-100"
            })
            return newClass
        }
        const drugsUsedData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: DASHA_VISITS_BY_SITES_COLOURS,
                    data: [],
                    barThickness: 50
                }]
            }
        })
        const dataKeys = ref([{
            rowTitle: 'Total Logins', property: 'campusCheckinsTotal'
        }, {
            rowTitle: 'Total no. of clients', property: 'numOpenEpisodes'
        }, {
            rowTitle: 'Total no. of Ted assessments', property: 'completedComprehensiveAssessments'
        }, {
            rowTitle: 'Total no. of brief assessments', property: 'completedBriefOrDdAssessments',
            subRows: [{
                rowTitle: 'Number of these completed at Street Uni', property: 'completedBriefOrDdAssessmentsAtTsu'
            }]
        }, {
            rowTitle: 'Total no. of closures', property: 'closedEpisodes'
        }, {
            //TODO: ccheck if this kind of thing actually works, the property is used to drill down into the object
            rowTitle: 'Treatment outcome Follow Ups', property: 'treatmentOutcomeFollowups',
            subRows: [{
                rowTitle: 'Stage 1', property: 'stage1'
            }, {
                rowTitle: 'Stage 2', property: 'stage2'
            }, {
                rowTitle: 'Stage 3', property: 'stage3'
            }, {
                rowTitle: 'Stage 4', property: 'stage4'
            }, {
                rowTitle: 'Stage 5', property: 'stage5'
            }]
        }, {
            rowTitle: 'Total no. of CSQ-8', property: 'completedCsqAssessments'
        }, {
            rowTitle: 'Total no. of eligible CSQ-8', property: 'eligibleCsqAssessments'
        }, {
            rowTitle: 'Average wait time', property: 'avgWaitTime'
        }, {
            rowTitle: 'Counselling Sessions', property: 'numCounsellingSessions'
        }])

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(data, (newData) => {
            //getting the forms data
            dataTable.value = []
            formsData.value.chartData.datasets[0].data = [
                newData.completedComprehensiveAssessments.total,
                newData.treatmentOutcomeFollowups[0].total,
                newData.completedCsqAssessments.total
            ]
            const countByDrugGroup = newData.countByDrugGroup
            drugsUsedData.value.chartData.labels = Object.keys(countByDrugGroup)
            drugsUsedData.value.chartData.datasets[0].data = Object.values(countByDrugGroup)

            for (const key of dataKeys.value) {
                const value = newData[key.property]
                if (isNullOrUndefined(value)) continue
                let data = {}
                if (Array.isArray(value)) {
                    if (key.property == 'treatmentOutcomeFollowups') {
                        // Handle data for case rowTitle=Treatment outcome Follow Ups
                        data = {
                            kpi: key.rowTitle,
                            total: value?.[0].total,
                            annualKpi:'',
                            monthlyKpi: '',
                            ytd: '',
                        }
                    } else {
                        data = {
                            kpi: key.rowTitle,
                            total: 0,
                            annualKpi:'',
                            monthlyKpi: '',
                            ytd: '',
                        }
                    }
                } else {
                    data = {
                        kpi: key.rowTitle,
                        total: value.total,
                        annualKpi: getRoundedValue(value.annual_kpi),
                        monthlyKpi: getRoundedValue(value.monthly_kpi),
                        ytd: getRoundedValue(value.ytd),
                    }
                }
                if (key.subRows) {
                    data.subRows = []
                    if (key.property == 'treatmentOutcomeFollowups') {
                        // Handle data for case rowTitle=Treatment outcome Follow Ups
                        value.map((e, index) => {
                            if ( index > 0 ){
                                data.subRows.push({
                                    kpi: e.stage,
                                    total: e.total,
                                    annualKpi: '',
                                    monthlyKpi: '',
                                    ytd: e.ytd,
                                })
                            }
                        })
                    } else {
                        // now we have to get the sub rows as well
                        key.subRows.map((e, index) => {
                            const subValue = newData[e.property]
                            if (subValue) {
                                data.subRows.push({
                                    kpi: e.rowTitle,
                                    total: getRoundedValue(subValue.total),
                                    annualKpi: getRoundedValue(value.annualKpi),
                                    monthlyKpi: getRoundedValue(value.monthlyKpi),
                                    ytd: getRoundedValue(subValue.ytd),
                                })
                                }
                        })
                    }
                }
                dataTable.value.push(data)
            }
        }, {deep: true})
        //#endregion

        return {
            dataTable,
            formsData,
            drugsUsedData,
            expanded,
            getRoundedValue,
            setRowClass
        }
    }
}
</script>
