var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-4 mb-4"},[_c('div',{staticClass:"col-span-10 p-4 l-box-shadow bg-white-pure rounded"},[_c('div',{staticClass:"grid grid-cols-3 gap-6 gap-y-8"},[_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"user-friends"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 groups"},[_vm._v(" Workshop Attendees "),_c('v-tooltip',{attrs:{"top":"","attach":".groups"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("No. of workshop attendees during the period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(" "+_vm._s(_vm.getSubPropValue(_vm.data.numWorkshopAttendees, 'total'))+" ")])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"users"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 total-clients"},[_vm._v(" Total Clients "),_c('v-tooltip',{attrs:{"top":"","attach":".total-clients"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Total no. of episodes that were open during the period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(" "+_vm._s(_vm.data.openClients)+" ")])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"sign-language"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 atsi"},[_vm._v(" First Nations "),_c('v-tooltip',{attrs:{"top":"","attach":".atsi"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("No. of First Nations clients who were open during the period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(" "+_vm._s(_vm.getAsPercentage(_vm.data.numAtsi))+" ")])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }