<template>
    <div class="grid grid-cols-8 gap-4 mb-4">
        <div class="col-span-4 l-box-shadow bg-white-pure rounded p-4">
            <h2 class="text-black text-base lg:text-lg mb-4">Referral Sources</h2>
            <div style="height: 300px; width: auto; margin: auto; display: block">
                <BarChart
                    :height="300"
                    :title="referralSourcesData.title"
                    :chartData="referralSourcesData.chartData"
                    :label="referralSourcesData.label"
                    :scope="referralSourcesData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
        <div class="col-span-4 l-box-shadow bg-white-pure rounded p-4">
            <h2 class="text-black text-base lg:text-lg mb-4">Gender</h2>
            <div style="height: 300px; width: auto; margin: auto; display: block">
                <BarChart
                    :height="300"
                    :title="genderData.title"
                    :chartData="genderData.chartData"
                    :label="genderData.label"
                    :scope="genderData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import {DASHA_REFERRAL_COLOURS, DASHA_GENDER_COLOURS} from '@/utils/constants'

export default {
    name: 'ResidentialRow2Charts',
    components: {BarChart},
    props: {
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props) {
        const referralSourcesData = computed(() => {
            return {
                title: '',
                label: (tooltipItem, data) => {
                    const total = data.datasets[0].data[tooltipItem.index]
                    return `${tooltipItem.label} ${total}`
                },
                scope: {
                    min: 0
                },
                chartData: {
                    labels: Object.keys(props.dataObj.numPerReferralSource),
                    datasets: [
                        {
                            backgroundColor: DASHA_REFERRAL_COLOURS,
                            data: Object.values(props.dataObj.numPerReferralSource),
                            barThickness: 50
                        }
                    ]
                }
            }
        })

        const genderData = computed(() => {
            return {
                title: '',
                label: (tooltipItem, data) => {
                    const total = data.datasets[0].data[tooltipItem.index]
                    return `${tooltipItem.label} ${total}`
                },
                scope: {
                    min: 0
                },
                chartData: {
                    labels: Object.keys(props.dataObj.numClientsByGender),
                    datasets: [
                        {
                            backgroundColor: DASHA_GENDER_COLOURS,
                            data: Object.values(props.dataObj.numClientsByGender),
                            barThickness: 50
                        }
                    ]
                }
            }
        })

        return {
            referralSourcesData,
            genderData
        }
    }
}
</script>
