<template>
    <div class="grid grid-cols-2 gap-4 mb-4">
        <div class="col-span-1 rounded p-4 l-box-shadow bg-white-pure">
            <div class="flex items-center justify-between mb-1">
                <h1 class="text-black text-base lg:text-lg">Visits by Sites</h1>
            </div>
            <BarChart
                v-if="!loading"
                :height="540"
                :title="campusCheckinsData.title"
                :chartData="campusCheckinsData.chartData"
                :label="campusCheckinsData.label"
                :scope="campusCheckinsData.scope"
                :showDataLabels="true"
            />
        </div>
        <div class="col-span-1 rounded p-4 l-box-shadow bg-white-pure">
            <div class="flex items-center justify-between mb-1">
                <h1 class="text-black text-base lg:text-lg">Visits by Workshop Types</h1>
            </div>
            <BarChart
                v-if="!loading"
                :height="540"
                :title="visitsByWorkshopData.title"
                :chartData="visitsByWorkshopData.chartData"
                :label="visitsByWorkshopData.label"
                :scope="visitsByWorkshopData.scope"
                :showDataLabels="true"
            />
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import {
    DASHA_VISITS_BY_SITES_COLOURS,
    DASHA_VISITS_BY_WORKSHOP_TYPES_COLOURS,
    MAX_LABEL_LENGTH
} from '@/utils/constants'

export default {
    name: 'StreetUniVisitCharts',
    components: {BarChart},
    props: {
        dataObj: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const campusCheckinsData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: DASHA_VISITS_BY_SITES_COLOURS,
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })
        const visitsByWorkshopData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: DASHA_VISITS_BY_WORKSHOP_TYPES_COLOURS,
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(
            data,
            (newData) => {

                const campusCheckins = newData.campusCheckinsBySite
                campusCheckinsData.value.chartData.labels = Object.keys(campusCheckins)
                campusCheckinsData.value.chartData.datasets[0].data = Object.values(campusCheckins)

                const visitsByWorkshop = newData.workshopAttendeesByWorkshopTypeGroup
                visitsByWorkshopData.value.chartData.labels = Object.keys(visitsByWorkshop).map(
                    (v) => {
                        if (v?.length > MAX_LABEL_LENGTH) {
                            // for now let's assume we have the delimiter as ', '
                            const vArray = v.split(', ')
                            return [
                                `${vArray.slice(0, vArray.length / 2).join(', ')},`,
                                vArray.slice(vArray.length / 2).join(', ')
                            ]
                        }
                        // returning a '-' for completeness, we should always have a label, this way we don't throw an error
                        return v || '-'
                    }
                )
                visitsByWorkshopData.value.chartData.datasets[0].data =
                    Object.values(visitsByWorkshop)
            },
            {deep: true}
        )
        //#endregion
        return {
            campusCheckinsData,
            visitsByWorkshopData
        }
    }
}
</script>
