<template>
    <div>
        <div class="grid grid-cols-6 gap-4 p-4 l-box-shadow bg-white-pure rounded mb-4">
            <div class="flex flex-row">
                <fa-icon icon="folder-open" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1">
                        Programs Open (Current)
                        <!--NOTE: Make tooltip a component with 'attach' attribute to attach to the correct div class (the div outside of Programs Open in this case, above this comment)-->
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                /></span>
                            </template>
                            <span>
                                This is current real-time data (updated hourly) and is not
                                affected by the date range filters.
                            </span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.curOpenEpisodes}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="clock" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1">
                        Waiting List (Current)
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                    />
                                </span>
                            </template>
                            <span>
                                This is current real-time data (updated hourly) and is not
                                affected by the date range filters.
                            </span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.curAssessedSuitable}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="users" class="mr-2 text-primary-light" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1">
                        Total Attendees
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                    />
                                </span>
                            </template>
                            <span>Total no. of YP attended PALM during period selected.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.newOpenedEpisodes}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="house-user" class="mr-2 text-primary-light" />
                <div class="flex flex-col ">
                    <div class="text-xs mb-1 text-grey-1">
                        Occupancy
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                    />
                                </span>
                            </template>
                            <span>Occupancy for period selected.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{`${getRoundedValue(dataObj.occupancy)}%`}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="clipboard-check" class="mr-2 text-primary-light text-lg" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1">
                        Assessments Done
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                    />
                                </span>
                            </template>
                            <span>Assessments completed during period selected.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.completedComprehensiveAssessments}}</div>
                </div>
            </div>
            <div class="flex flex-row">
                <fa-icon icon="comments" class="mr-2 text-primary-light text-lg" />
                <div class="flex flex-col">
                    <div class="text-xs mb-1 text-grey-1">
                        Counselling Sessions
                        <v-tooltip top>
                            <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                    <fa-icon
                                        icon="question-circle"
                                        class="mr-2 text-xs text-grey-3"
                                    />
                                </span>
                            </template>
                            <span>No. of Counselling sessions during period selected.</span>
                        </v-tooltip>
                    </div>
                    <div class="text-2xl font-bold text-black">{{dataObj.numCounsellingSessions}}</div>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div class="grid grid-cols-10 gap-4 mb-4">
                <div class="col-span-4 rounded p-4 l-box-shadow bg-white-pure rounded">
                    <div class="flex items-center justify-between mb-1">
                        <h2 class="text-black text-base lg:text-lg">Drugs Used</h2>
                    </div>
                    <!-- <img src="@/assets/placeholder/drugs-used.png" alt="" class="block" /> -->
                    <div class="block">
                        <BarChart
                            :height="300"
                            :title="drugsUsedData.title"
                            :chartData="drugsUsedData.chartData"
                            :label="drugsUsedData.label"
                            :scope="drugsUsedData.scope"
                            :showDataLabels="true"
                        />
                    </div>
                </div>
                <div class="col-span-6">
                    <ResidentialRow1Charts :data-obj="dataObj" />
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mb-4">
                <div class="col-span-4 p-4 l-box-shadow bg-white-pure rounded mb-4">
                    <div class="grid grid-cols-2 gap-4 gap-y-8">
                        <div class="flex flex-row">
                            <fa-icon icon="sync-alt" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Retention Rate
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>Retention rate of yp who attended PALM during period selected.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{dataObj.retentionRate}}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="user-friends" class="mr-2 text-primary-light text-lg" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Groups
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>No. of groups ran during period selected.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{dataObj.numWorkshops}}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="baby" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Average Age
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>This refers to full assessment completed during the selected period.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{dataObj.avgAgeOfOpenClients}}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="sign-language" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    First Nations
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>This refers to full assessment completed during the selected period.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{`${getRoundedValue(dataObj.numAtsi)}%`}}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="heartbeat" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Attempted Suicide
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>This refers to full assessment completed during the selected period.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{dataObj.numClientsAttemptedSuicide}}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="gavel" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Juvenile/Youth Justice
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>This refers to full assessment completed during the selected period.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{ getSubPropValue(dataObj.numPerReferralSource, 'JJ / YJ') != '-' ? getSubPropValue(dataObj.numPerReferralSource, 'JJ / YJ') : 0 }}</div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <fa-icon icon="briefcase-medical" class="mr-2 text-primary-light" />
                            <div class="flex flex-col">
                                <div class="text-xs mb-1 text-grey-1">
                                    Mental Health Issues
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs2}">
                                            <span v-bind="attrs2" v-on="on">
                                                <fa-icon
                                                    icon="question-circle"
                                                    class="mr-2 text-xs text-grey-3"
                                            /></span>
                                        </template>
                                        <span>This refers to full assessment completed during the selected period.</span>
                                    </v-tooltip>
                                </div>
                                <div class="text-2xl font-bold text-black">{{dataObj.numClientsMentalHealth}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-8">
                    <ResidentialRow2Charts :data-obj="dataObj" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from '@vue/composition-api'
import ResidentialRow2Charts from '@/components/clinical/partials/ResidentialRow2Charts'
import ResidentialRow1Charts from '@/components/clinical/partials/ResidentialRow1Charts'
import BarChart from '@/components/charts/BarChart'
import {useDashaHelpers} from '@/composables/dashaHelpers'
import {DASHA_VISITS_BY_SITES_COLOURS} from '@/utils/constants'

export default {
    name: 'ResidentialClinical',
    components: {BarChart, ResidentialRow1Charts, ResidentialRow2Charts},
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const periodOptions = ref([
            'Last 7 days',
            'Month to date',
            'Financial year',
            'Custom range'
        ])
        const yearOptions = ref(['2021', '2020', '2019'])
        const {getSubPropValue, getRoundedValue} = useDashaHelpers()

        const drugsUsedData = ref({
            title: '',
            label: (tooltipItem, data) => {
                const total = data.datasets[0].data[tooltipItem.index]
                return `${tooltipItem.label} ${total}`
            },
            scope: {
                min: 0
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: DASHA_VISITS_BY_SITES_COLOURS,
                        data: [],
                        barThickness: 50
                    }
                ]
            }
        })

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        //#region watches
        watch(
            data,
            (newData) => {
                const countByDrugGroup = newData.countByDrugGroup

                drugsUsedData.value.chartData.labels = Object.keys(countByDrugGroup)
                drugsUsedData.value.chartData.datasets[0].data = Object.values(countByDrugGroup)
            },
            {deep: true}
        )
        //#endregion

        return {
            periodOptions,
            yearOptions,
            drugsUsedData,
            getSubPropValue,
            getRoundedValue
        }
    }
}
</script>
