var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-6 gap-4 p-4 l-box-shadow bg-white-pure rounded mb-4"},[_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"folder-open"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Programs Open (Current) "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v(" This is current real-time data (updated hourly) and is not affected by the date range filters. ")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.curOpenEpisodes))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"clock"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Waiting List (Current) "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v(" This is current real-time data (updated hourly) and is not affected by the date range filters. ")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.curAssessedSuitable))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"users"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Total Attendees "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Total no. of YP attended PALM during period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.newOpenedEpisodes))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"house-user"}}),_c('div',{staticClass:"flex flex-col "},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Occupancy "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Occupancy for period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(((_vm.getRoundedValue(_vm.dataObj.occupancy)) + "%")))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"clipboard-check"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Assessments Done "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("Assessments completed during period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.completedComprehensiveAssessments))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"comments"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Counselling Sessions "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("No. of Counselling sessions during period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.numCounsellingSessions))])])],1)]),(!_vm.loading)?_c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-4 mb-4"},[_c('div',{staticClass:"col-span-4 rounded p-4 l-box-shadow bg-white-pure rounded"},[_vm._m(0),_c('div',{staticClass:"block"},[_c('BarChart',{attrs:{"height":300,"title":_vm.drugsUsedData.title,"chartData":_vm.drugsUsedData.chartData,"label":_vm.drugsUsedData.label,"scope":_vm.drugsUsedData.scope,"showDataLabels":true}})],1)]),_c('div',{staticClass:"col-span-6"},[_c('ResidentialRow1Charts',{attrs:{"data-obj":_vm.dataObj}})],1)]),_c('div',{staticClass:"grid grid-cols-12 gap-4 mb-4"},[_c('div',{staticClass:"col-span-4 p-4 l-box-shadow bg-white-pure rounded mb-4"},[_c('div',{staticClass:"grid grid-cols-2 gap-4 gap-y-8"},[_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"sync-alt"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Retention Rate "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("Retention rate of yp who attended PALM during period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.retentionRate))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"user-friends"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Groups "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("No. of groups ran during period selected.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.numWorkshops))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"baby"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Average Age "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("This refers to full assessment completed during the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.avgAgeOfOpenClients))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"sign-language"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" First Nations "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("This refers to full assessment completed during the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(((_vm.getRoundedValue(_vm.dataObj.numAtsi)) + "%")))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"heartbeat"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Attempted Suicide "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("This refers to full assessment completed during the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.numClientsAttemptedSuicide))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"gavel"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Juvenile/Youth Justice "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("This refers to full assessment completed during the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getSubPropValue(_vm.dataObj.numPerReferralSource, 'JJ / YJ') != '-' ? _vm.getSubPropValue(_vm.dataObj.numPerReferralSource, 'JJ / YJ') : 0))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"briefcase-medical"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1"},[_vm._v(" Mental Health Issues "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs2 = ref.attrs2;
return [_c('span',_vm._g(_vm._b({},'span',attrs2,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}],null,false,2492549820)},[_c('span',[_vm._v("This refers to full assessment completed during the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.numClientsMentalHealth))])])],1)])]),_c('div',{staticClass:"col-span-8"},[_c('ResidentialRow2Charts',{attrs:{"data-obj":_vm.dataObj}})],1)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h2',{staticClass:"text-black text-base lg:text-lg"},[_vm._v("Drugs Used")])])}]

export { render, staticRenderFns }