<template>
    <div class="grid grid-cols-6 gap-4">
        <div class="col-span-3 rounded p-4 l-box-shadow bg-white-pure rounded">
            <h2 class="text-black text-base lg:text-lg mb-1">Completion Rates</h2>
            <div class="block">
                <BarChart
                    :height="300"
                    :title="completionRatesData.title"
                    :chartData="completionRatesData.chartData"
                    :label="completionRatesData.label"
                    :scope="completionRatesData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
        <div class="col-span-3 rounded p-4 l-box-shadow bg-white-pure">
            <h2 class="text-black text-base lg:text-lg mb-1">Forms</h2>
            <div class="h-300 w-auto m-auto block">
                <BarChart
                    :height="300"
                    :title="formsData.title"
                    :chartData="formsData.chartData"
                    :label="formsData.label"
                    :scope="formsData.scope"
                    :showDataLabels="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '@vue/composition-api'
import BarChart from '@/components/charts/BarChart'
import {DASHA_FORMS_COLOURS, DASHA_REFERRAL_COLOURS} from '@/utils/constants'

export default {
    name: 'ResidentialRow1Charts',
    components: {BarChart},
    props: {
        dataObj: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props) {
        const formsData = computed(() => {
            return {
                title: '',
                label: (tooltipItem, data) => {
                    const total = data.datasets[0].data[tooltipItem.index]
                    return `${tooltipItem.label} ${total}`
                },
                scope: {
                    min: 0
                },
                chartData: {
                    labels: props.dataObj.completedForms.map((e) => e.title),
                    datasets: [
                        {
                            backgroundColor: DASHA_FORMS_COLOURS,
                            data: props.dataObj.completedForms.map((e) => e.value),
                            barThickness: 50
                        }
                    ]
                }
            }
        })

        const completionRatesData = computed(() => {
            return {
                title: '',
                label: (tooltipItem, data) => {
                    const total = data.datasets[0].data[tooltipItem.index]
                    return `${tooltipItem.label} ${total}`
                },
                scope: {
                    min: 0
                },
                chartData: {
                    labels: props.dataObj.countByOutcome.map((e) => e.value),
                    datasets: [
                        {
                            backgroundColor: DASHA_REFERRAL_COLOURS,
                            data: props.dataObj.countByOutcome.map((e) => Number(e.percent)/100),
                            barThickness: 50
                        }
                    ]
                }
            }
        })

        return {
            completionRatesData,
            formsData
        }
    }
}
</script>
