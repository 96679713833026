var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-4 mb-4"},[_c('div',{staticClass:"col-span-6 l-box-shadow bg-white-pure p-4 rounded c-staff__table editable_table expandable_table flex flex-col w-full"},[_c('v-data-table',{staticClass:"a-table w-full",attrs:{"id":"NonResKpiTable","headers":_vm.headers,"items":_vm.dataTable,"items-per-page":15,"item-key":"kpi","item-class":_vm.setRowClass,"show-expand":"","single-expand":"","expanded":_vm.expanded,"hide-default-footer":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"flex-row m-0 w-full sub-row-td",attrs:{"colspan":headers.length}},_vm._l((item.subRows),function(r,idx){return _c('table',{key:((r.kpi) + "-" + idx),staticClass:"table-auto w-full"},[(item.kpi == 'Treatment outcome Follow Ups')?_c('tr',[_c('td',{staticClass:"text-left w-full"},[_vm._v("- "+_vm._s(r.kpi))]),_c('td',{staticClass:"left w-full"}),_c('td',{staticClass:"left w-full"}),_c('td',{staticClass:"text-left ml-2 w-full"},[_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(r.total)+" ")])]),_c('td',{staticClass:"left w-full"})]):_c('tr',[_c('td',[_vm._v("- "+_vm._s(r.kpi))]),_c('td',[_vm._v(_vm._s(r.total))]),_c('td',{staticClass:"left"},[_vm._v(_vm._s(r.annualKpi))]),_c('td',{staticClass:"left"},[_vm._v(_vm._s(r.monthlyKpi))]),_c('td',{staticClass:"left"},[_vm._v(_vm._s(r.ytd))])])])}),0)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.subRows)?_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-span-4"},[_c('div',{staticClass:"rounded p-4 l-box-shadow bg-white-pure mb-4"},[_vm._m(0),_c('div',{staticClass:"flex items-center justify-center w-3/5"},[(!_vm.loading)?_c('BarChart',{attrs:{"height":300,"title":_vm.drugsUsedData.title,"chartData":_vm.drugsUsedData.chartData,"label":_vm.drugsUsedData.label,"scope":_vm.drugsUsedData.scope,"showDataLabels":true}}):_vm._e()],1)]),_c('div',{staticClass:"rounded p-4 l-box-shadow bg-white-pure"},[_vm._m(1),_c('div',{staticClass:"flex items-center justify-center w-3/5"},[(!_vm.loading)?_c('BarChart',{attrs:{"height":300,"title":_vm.formsData.title,"chartData":_vm.formsData.chartData,"label":_vm.formsData.label,"scope":_vm.formsData.scope,"showDataLabels":true}}):_vm._e()],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h1',{staticClass:"text-black text-base lg:text-lg"},[_vm._v("Drugs Used")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h1',{staticClass:"text-black text-base lg:text-lg"},[_vm._v("Forms")])])}]

export { render, staticRenderFns }