export function useDashaHelpers(variables) {
    //#region functions
    const isNullOrUndefined = (val) => {
        return val === null || typeof val === 'undefined'
    }
    /**
     * function to round the value by a specified number of decimal places
     *
     * @param val {string|number}
     * @param dp {number}
     * @returns {string|number}
     */
    const getRoundedValue = (val, dp = 2) => {
        if (isNullOrUndefined(val)) return ''
        // becomes the number of places we want to get, so if we pass dp=1, we get to 1dp
        else if (typeof val == "string") return val
        
        const multiplier = Math.pow(10, dp)
        // return Math.round((val + Number.EPSILON) * multiplier) / multiplier
        return Math.round(val * multiplier) / multiplier
    }
    
    const getAsPercentage = (val) => {
        if (isNullOrUndefined(val)) return '-'
        return `${getRoundedValue(val)}%`
    }
    
    /**
     * function to return the value from a property within an objects property
     * e.g. obj.foo.bar, so that the ui doesn't throw an error
     *
     * @param obj {object}
     * @param prop {string}
     * @returns {string}
     */
    const getSubPropAsPercentage = (obj, prop) => {
        if (!obj || isNullOrUndefined(obj[prop])) return '-'
        
        return getAsPercentage(obj[prop])
    }
    
    const getSubPropValue = (obj, prop) => {
        if (!obj || isNullOrUndefined(obj[prop])) return '-'
        
        return obj[prop]
    }
    //#endregion
    
    return {
        getRoundedValue,
        getAsPercentage,
        getSubPropAsPercentage,
        getSubPropValue,
        isNullOrUndefined,
    }
}
