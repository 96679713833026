<template>
    <div class="p-5">
        <ClinicalFilter v-model="filter" />
        <div
            v-if="showInitial"
            class="l-box-shadow bg-white-pure p-4 mb-4 rounded flex flex-col w-full"
        >
            <img src="@/assets/images/dasha-filters.png" alt="" class="welcomeImage m-auto my-4" />
            <div class="text-center mb-4">
                <h1 class="text-black text-xl lg:text-2xl">Clinical Data Reports</h1>
                <div class="text-sm">Select the filters above to load your report!</div>
            </div>

        </div>
        <div class="flex flex-col" v-else>
            <!--500 error design START-->
            <div 
                v-if="showError"
                class="l-box-shadow bg-white-pure p-4 mb-4 rounded flex flex-col w-full"
            >
                <img src="@/assets/images/no-access.png" alt="" class="welcomeImage m-auto my-4" />
                <div class="text-center mb-4">
                    <h1 class="text-black text-xl lg:text-2xl">Unable to load results</h1>
                    <div class="text-sm">Please try again by refreshing or contact your system administrator.</div>
                </div>
            </div>
            <!--500 error design END-->
            <div v-if="!showError">
                <ResidentialClinical
                    v-if="filter.clinicalDataOption === 'residential'"
                    :headers="headers"
                    :data-table="tableRows"
                    :data-obj="clinicalData"
                    :loading="loading"
                />
                <NonResidentialClinical
                    v-else-if="filter.clinicalDataOption === 'nonresidential'"
                    :headers="headers"
                    :data-obj="clinicalData"
                    :loading="loading"
                />
                <StreetUniClinical
                    v-else
                    :headers="headers"
                    :data-obj="clinicalData"
                    :loading="loading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import StreetUniClinical from '@/components/clinical/StreetUniClinical'
import NonResidentialClinical from '@/components/clinical/NonResidentialClinical'
import ResidentialClinical from '@/components/clinical/ResidentialClinical'
import axiosInstance from '@/utils/axios'
import ClinicalFilter from '@/components/clinical/partials/ClinicalFilter'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useActions } = createNamespacedHelpers('')

export default {
    name: "Clinical",
    components: { ClinicalFilter, ResidentialClinical, NonResidentialClinical, StreetUniClinical },
    setup() {
        const filter = ref({
            clinicalDataOption: 'streetuni',
            // dateRange: [format(addDays(new Date(), -30), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
            dateRange: [],
            locationProgramId: null,  // TODO: remove this after testing since we don't need a default
            programId: null,
        })
        const items = ref(['Residential', 'Non-Residential', 'Street Uni'])
        const episodeRegions = ref(['All', 'ACT', 'NSW', 'QLD'])
        const periodOptions = ref(['Last 7 days', 'Month to date', 'Financial year', 'Custom range'])
        const yearOptions = ref(['2021', '2020', '2019'])
        const headers = ref([
            { text: 'KPI', align: 'left', sortable: false, value: 'kpi' },
            { text: 'Annual KPI', align: 'left', sortable: false, value: 'annualKpi' },
            { text: 'Monthly KPI', align: ' left', value: 'monthlyKpi' },
            { text: 'Actual', align: 'left', value: 'total' },
            { text: 'YTD Actual', align: ' d-none', value: 'ytd' },
            { text: '', value: 'data-table-expand' }
        ])
        const messageType = ref('success')
        const message = ref('')
        const clinicalData = ref({})
        const loading = ref(false)
        const { openModal, closeModal } = useActions(['openModal', 'closeModal'])
        const showError = ref(false)

        //#region functions
        const getDashaData = async (dataEndpoint) => {
            try {
                loading.value = true
                //opening Modal
                openModal({
                    payload: {text: 'Retrieving clinical data, please wait...'},
                    modalName: 'ModalPleaseWait'
                })
                const data = await axiosInstance.post(
                    `/reports/dasha/${dataEndpoint}/`,
                    snakecaseKeys({
                        startDate: filter.value.dateRange[0],
                        endDate: filter.value.dateRange[1],
                        locationProgramId: filter.value?.locationProgram?.locationProgramId
                    })
                )
                showError.value = false
                const parsed = camelcaseKeys(data.data)
                clinicalData.value = {...parsed}
            } catch (error) {
                console.log('error', error)
                showError.value = true
                messageType.value = 'error'
                message.value = 'Failed to download document'
            } finally {
                loading.value = false
                closeModal()
            }
        }
        //#endregion
        const tableRows = ref([
            {
                KPI: 'Total no. of clients',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Total no. of TED assessments',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Total no. of brief assessments',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: '- Number of these completed at Street Uni',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Total no. of closures',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Treatment outcome Follow Ups',
                actual: null,
                monthlyKPI: null,
                YTDActual: null,
            },
            {
                KPI: '- Stage 1',
                actual: '50%',
                monthlyKPI: '50%',
                YTDActual: '34/44',
            },
            {
                KPI: '- Stage 2',
                actual: '30%',
                monthlyKPI: '30%',
                YTDActual: '9/19',
            },
            {
                KPI: '- Stage 3',
                actual: '20%',
                monthlyKPI: '20%',
                YTDActual: '3/6',
            },
            {
                KPI: '- Stage 4',
                actual: null,
                monthlyKPI: null,
                YTDActual: '2/2',
            },
            {
                KPI: '- Exit',
                actual: null,
                monthlyKPI: null,
                YTDActual: '0',
            },
            {
                KPI: 'Total no. of CSQ-8',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Total no. of eligible CSQ-8',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Average wait time',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
            {
                KPI: 'Counselling Sessions',
                actual: 250,
                monthlyKPI: 21,
                YTDActual: 109,
            },
        ])
        const streetUniTable = ref([
            {
                KPI: 'Stuart logins total',
                actual: null,
                monthlyKPI: null,
                YTDActual: 282,
            },
            {
                KPI: 'Service users total',
                actual: null,
                monthlyKPI: null,
                YTDActual: 92,
            },
            {
                KPI: 'Brief assessments total',
                actual: '75% of Stuart logins',
                monthlyKPI: '75%',
                YTDActual: 63,
            },
            {
                KPI: 'BA - Ted assessments total',
                actual: '75% of Brief assesments',
                monthlyKPI: '30%',
                YTDActual: 49,
            },
            {
                KPI: 'Total no. of workshops',
                actual: null,
                monthlyKPI: null,
                YTDActual: 145,
            },
            {
                KPI: 'Total workshop attendees',
                actual: null,
                monthlyKPI: null,
                YTDActual: 153,
            },
        ])

        //#region computed properties
        const showInitial = computed(
            () => filter.value?.dateRange.length < 2 ||
                !filter.value?.locationProgram?.locationProgramId ||
                !filter.value?.locationProgram?.locationId
        )
        //#endregion

        //#region watches
        watch(filter, (newFilter) => {
            console.log('filter has changed', newFilter)
            if (showInitial.value) return

            getDashaData(newFilter.clinicalDataOption)
        }, { deep: true })
        //#endregion

        return {
            filter,
            items,
            episodeRegions,
            periodOptions,
            yearOptions,
            headers,
            tableRows,
            streetUniTable,
            clinicalData,
            loading,
            showInitial,
            showError
        }
    }
}
</script>
