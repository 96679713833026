<template>
    <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mb-5 flex no-underline max-h-16 items-center">
        <div class="w-240 mr-2">
            <v-select
                hide-details
                :items="items"
                label="Clinical Data"
                v-model="clinicalFilter.clinicalDataOption"
            ></v-select>
        </div>
        <div class="w-400 mr-2">
            <LocationProgramFilter
                class="w-full grid grid-cols-2 gap-2"
                hide-details
                :user-scoped="false"
                v-model="clinicalFilter.locationProgram"
                @change="filterChangeHandler"
            />
        </div>
        <div class="w-240 mr-2">
            <v-select
                hide-details
                class="w-240"
                :items="periodOptions"
                label="Period"
                v-model="period"
                @change="getDatePeriod"
            ></v-select>
        </div>
        <div
            v-if="period === 'custom_range'"
            class="w-240 mr-2"
        >
            <DateRangePicker
                class="w-240"
                label="dd/mm/yy - dd/mm/yy"
                :row="false"
                :wrap="false"
                :outlined="false"
                :dense="false"
                :single-line="false"
                v-model="clinicalFilter.dateRange"
            />
        </div>
        <div class="ml-4 text-xs text-grey-3">
            <fa-icon icon="exclamation-triangle" class="mr-1" />
            Please note all data is updated every hour.
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import DateRangePicker from '@/components/partials/DateRangePicker'
import { format, addDays, startOfMonth, addYears, setMonth } from 'date-fns'

export default {
    name: 'ClinicalFilter',
    components: { DateRangePicker, LocationProgramFilter },
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, { emit }) {
        const clinicalFilter = ref({})
        const period = ref(null)
        const items = ref([{
            text: 'Residential', value: 'residential'
        }, {
            text: 'Non-Residential', value: 'nonresidential'
        }, {
            text: 'Street Uni', value: 'streetuni'
        }])
        const periodOptions = ref([
            { text: 'Last 7 days', value: 'last_7_days' },
            { text: 'Month to date', value: 'month_to_date' },
            { text: 'Financial year', value: 'current_financial_year' },
            { text: 'Custom range', value: 'custom_range' }
        ])

        //#region functions
        const filterChangeHandler = () => {
            console.log('we are emitting the clinical filter', clinicalFilter.value)
            emit('input', clinicalFilter.value)
        }

        const getDatePeriod = () => {
            console.log('period changed', period.value)
            let toDate = null, fromDate = null, previousFy
            switch (period.value) {
                case 'last_7_days':
                    toDate = format(new Date(), 'yyyy-MM-dd')
                    fromDate = format(addDays(new Date(), -7), 'yyyy-MM-dd')
                    break;
                case 'month_to_date':
                    toDate = format(new Date(), 'yyyy-MM-dd')
                    fromDate = format(startOfMonth(new Date()), 'yyyy-MM-dd')
                    break;
                case 'current_financial_year':
                    // first we check if we are passed June, if we are we stay in the current year
                    if (new Date().getMonth() >= 6) previousFy = startOfMonth(setMonth(new Date(), 6))
                    else previousFy = startOfMonth(setMonth(addYears(new Date(), -1), 6))

                    console.log('previous fy', previousFy)
                    toDate = format(new Date(), 'yyyy-MM-dd')
                    fromDate = format(previousFy, 'yyyy-MM-dd')
                    break;
                default:
                    break;
            }
            if (toDate && fromDate) {
                clinicalFilter.value.dateRange = [fromDate, toDate]
                filterChangeHandler()
            }
        }
        //#endregions

        //#region lifecycle hooks
        onMounted(() => {
            clinicalFilter.value = props.value
        })
        //#endregion

        //#region computed props
        const computedProps = computed(() => props.value)
        //#endregion

        //#region watches
        watch(computedProps, (newFilter) => {
            clinicalFilter.value = newFilter
        })
        //#endregion

        return {
            period,
            items,
            clinicalFilter,
            periodOptions,
            filterChangeHandler,
            getDatePeriod,
        }
    }
}
</script>
