<template>
    <div>
        <div class="grid grid-cols-10 gap-4 mb-4">
            <div class="col-span-10 p-4 l-box-shadow bg-white-pure rounded">
                <div class="grid grid-cols-3 gap-6 gap-y-8">
                    <div class="flex flex-row">
                        <fa-icon icon="user-friends" class="mr-2 text-primary-light text-lg" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 groups">
                                Workshop Attendees
                                <v-tooltip top attach=".groups">
                                    <template v-slot:activator="{on, attrs3}">
                                        <span v-bind="attrs3" v-on="on">
                                            <fa-icon
                                                icon="question-circle"
                                                class="mr-2 text-xs text-grey-3"
                                        /></span>
                                    </template>
                                    <span>No. of workshop attendees during the period selected.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">
                                {{ getSubPropValue(data.numWorkshopAttendees, 'total') }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="users" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 total-clients">
                                Total Clients
                                <v-tooltip top attach=".total-clients">
                                    <template v-slot:activator="{on, attrs3}">
                                        <span v-bind="attrs3" v-on="on">
                                            <fa-icon
                                                icon="question-circle"
                                                class="mr-2 text-xs text-grey-3"
                                        /></span>
                                    </template>
                                    <span>Total no. of episodes that were open during the period selected.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">
                                {{ data.openClients }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <fa-icon icon="sign-language" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 atsi">
                                First Nations
                                <v-tooltip top attach=".atsi">
                                    <template v-slot:activator="{on, attrs3}">
                                        <span v-bind="attrs3" v-on="on">
                                            <fa-icon
                                                icon="question-circle"
                                                class="mr-2 text-xs text-grey-3"
                                        /></span>
                                    </template>
                                    <span>No. of First Nations clients who were open during the period selected.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">
                                {{ getAsPercentage(data.numAtsi) }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-row">
                        <fa-icon icon="gavel" class="mr-2 text-primary-light" />
                        <div class="flex flex-col">
                            <div class="text-xs mb-1 text-grey-1 jy-justice">
                                Juvenile/Youth Justice
                                <v-tooltip top attach=".jy-justice">
                                    <template v-slot:activator="{on, attrs3}">
                                        <span v-bind="attrs3" v-on="on">
                                            <fa-icon
                                                icon="question-circle"
                                                class="mr-2 text-xs text-grey-3"
                                        /></span>
                                    </template>
                                    <span>Tooltip.</span>
                                </v-tooltip>
                            </div>
                            <div class="text-2xl font-bold text-black">
                                {{ getSubPropValue(data.numPerReferralSource, 'JJ / YJ') }}
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {computed} from '@vue/composition-api'
import {useDashaHelpers} from '@/composables/dashaHelpers'

export default {
    name: 'AnnualKpiDataTable',
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        dataObj: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const {getAsPercentage, getSubPropValue} = useDashaHelpers()

        //#region computed properties
        const data = computed(() => props.dataObj)
        //#endregion

        return {
            data,
            getAsPercentage,
            getSubPropValue
        }
    }
}
</script>