var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-5 gap-4 p-4 l-box-shadow bg-white-pure mb-4"},[_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"folder-open"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 programs-open"},[_vm._v("Programs Open (Current) "),_c('v-tooltip',{attrs:{"top":"","attach":".programs-open"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("This is current real-time data (updated hourly) and is not affected by the date range filters.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.getSubPropValue(_vm.dataObj.curOpenEpisodes,'total')))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"sign-in-alt"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 relative referred"},[_vm._v("Referred (Current) "),_c('v-tooltip',{attrs:{"top":"","attach":".referred"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("This is current real-time data (updated hourly) and is not affected by the date range filters.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.newReferredEpisodes))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"user-check"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 assessed-suitable"},[_vm._v("Assessed Suitable (Current) "),_c('v-tooltip',{attrs:{"top":"","attach":".assessed-suitable"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("This is current real-time data (updated hourly) and is not affected by the date range filters.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(_vm._s(_vm.dataObj.curAssessedSuitableEpisodes))])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"clipboard-check"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 full-assessments-completed"},[_vm._v("Full Assessments Completed "),_c('v-tooltip',{attrs:{"top":"","attach":".full-assessments-completed"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("This is for the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(" "+_vm._s(_vm.getSubPropValue(_vm.dataObj.completedComprehensiveAssessments, 'total'))+" ")])])],1),_c('div',{staticClass:"flex flex-row"},[_c('fa-icon',{staticClass:"mr-2 text-primary-light text-lg",attrs:{"icon":"tasks"}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs mb-1 text-grey-1 brief-assessments-completed"},[_vm._v("Brief Assessments Completed "),_c('v-tooltip',{attrs:{"top":"","attach":".brief-assessments-completed"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs3 = ref.attrs3;
return [_c('span',_vm._g(_vm._b({},'span',attrs3,false),on),[_c('fa-icon',{staticClass:"mr-2 text-xs text-grey-3",attrs:{"icon":"question-circle"}})],1)]}}])},[_c('span',[_vm._v("This is for the selected period.")])])],1),_c('div',{staticClass:"text-2xl font-bold text-black"},[_vm._v(" "+_vm._s(_vm.getSubPropValue(_vm.dataObj.completedBriefOrDdAssessments, 'total'))+" ")])])],1)]),_c('NonResKpiAndCharts',{attrs:{"data-obj":_vm.dataObj,"headers":_vm.headers,"loading":_vm.loading}}),_c('ReferralStatistics',{attrs:{"data-obj":_vm.dataObj,"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }